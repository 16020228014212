<style lang="scss">
.custom-card-file {
  height: 200px;
  width: 150px;
  margin: auto;
  left: 0;
  right: 0;
  border-radius: 12px;

  cursor: pointer;
  text-align: center;
  padding: 20px;
  margin-top: 10px;
  top: 0;
  bottom: 0;
  position: relative;

  .status-badge {
    left: 0;
    width: 86px;
    position: absolute;
    font-weight: 500;
    font-size: 12px;
    border-radius: 11px;
    color: white;
    /* padding: 5px; */
    right: 0;
    margin: auto;
    top: 188px;
  }

  h6 {
    font-size: 14px;
  }

  p {
    margin: 1px;
    font-size: 12px;
  }

  span {
    font-size: 10px;
  }

  &:hover {
    box-shadow: 10px 5px 5px #e8e5e5;
  }
  .ico-more {
    position: absolute;
    top: 4px;
    /* left: 0; */
    right: 5px;
    margin: auto;
    cursor: pointer;
    &:hover {
      i {
        color: black;
      }
    }
  }
}
.card-add-new {
  height: 50px;
  width: 50px;
  background: #f3f2f2;
  padding: 14px;
  text-align: center;
  top: 0;
  bottom: 0;
  margin: auto;
  /* top: 0; */
  position: absolute;
  cursor: pointer;
  border-radius: 50px;
  &:hover {
    box-shadow: 5px 1px 4px #e8e5e5;
    background-color: #1bc5bd;
    i {
      color: white;
    }
  }
  i {
    color: black;
    font-weight: 500;
  }
}
</style>
<template>
  <div>
    <v-modal-contract-new></v-modal-contract-new>
    <v-modal-contract-edit></v-modal-contract-edit>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-body">
            <fieldset>
              <v-legend
                :title="'Employee\'s Contract'"
                :description="
                  'Employee contracts contain details like hours of work, the rate of pay, the employee\'s responsibilities, etc.'
                "
              ></v-legend>

              <div class="row">
                <div class="col-lg-12 text-center">
                  <data-loader
                    v-if="loader"
                    :type="'dark'"
                    :padding="10"
                  ></data-loader>
                  <v-no-record-card
                    v-if="getEmployeeContracts.data.length < 1 && !loader"
                    :title="'Employee\'s Contract'"
                    :src="'media/svg/contract_index_add.svg'"
                    :description="
                      'Employee contracts contain details like hours of work, the rate of pay, the employee\'s responsibilities'
                    "
                  >
                    <a class="btn btn-success" v-b-modal.modal-contract-new
                      >Create new contract</a
                    >
                  </v-no-record-card>
                </div>
              </div>

              <div
                class="row"
                v-if="getEmployeeContracts.data.length > 0 && !loader"
              >
                <div
                  class="col-lg-2"
                  v-for="(item, index) in getEmployeeContracts.data"
                  :key="index"
                >
                  <div
                    class="custom-card-file"
                    :style="
                      'border:1px solid ' +
                        (item.status.badge == 'success' ? '#36b11f' : '#b11f62')
                    "
                  >
                    <b-dropdown
                      class="ico-more"
                      size="sm"
                      variant="link"
                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      no-caret
                      right
                      no-flip
                    >
                      <template v-slot:button-content>
                        <i class="ki ki-bold-more-ver"></i>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover min-w-md-250px">
                        <b-dropdown-text tag="div" class="navi-item">
                          <a href="#" class="navi-link">
                            <span class="navi-icon">
                              <i class="flaticon2-group"></i>
                            </span>
                            <span class="navi-text">Download PDF</span>
                          </a>
                        </b-dropdown-text>

                        <b-dropdown-text tag="div" class="navi-item">
                          <a href="#" class="navi-link">
                            <span class="navi-icon">
                              <i class="flaticon2-group"></i>
                            </span>
                            <span class="navi-text">Edit Contract</span>
                          </a>
                        </b-dropdown-text>
                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>

                    <h6 class="text-primary">{{ item.created_at }}</h6>
                    <hr />
                    <p v-b-tooltip :title="'Contract start date'">
                      {{ item.start_date }}
                    </p>
                    <p v-b-tooltip :title="'Contract end date'">
                      {{ item.end_date }}
                    </p>
                    <p v-b-tooltip :title="'Contract designation'">
                      {{ item.designation }}
                    </p>
                    <hr />
                    <span>By: Roshan Shrestha</span>
                    <div
                      class="status-badge"
                      :style="
                        'background-color:' +
                          (item.status.badge == 'success'
                            ? '#36b11f'
                            : '#b11f62')
                      "
                    >
                      <span>{{ item.status.status }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2" v-if="getEmployeeContracts.btnStatus">
                  <div
                    v-b-tooltip
                    :title="'Add New Contract'"
                    class="card-add-new"
                    v-b-modal.modal-contract-new
                  >
                    <i class="flaticon2-add-1"></i>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import NoRecordFoundCard from "@/includes/content/NoRecordFoundCard";
import dataLoader from "@/includes/content/DataLoader";
import Legend from "@/includes/content/widgets/Legend";
// import { FETCH_EMPLOYEE_CONTRACTS } from "@/core/services/store/actions.type";
import modalContractNew from "@/pages/admin/employee/edit/contract/add";
import modalContractEdit from "@/pages/admin/employee/edit/contract/edit";
import { mapGetters } from "vuex";

// import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loader: false
    };
  },
  computed: {
    ...mapGetters(["getEmployeeContracts"])
  },
  components: {
    "v-legend": Legend,
    "data-loader": dataLoader,
    "v-modal-contract-new": modalContractNew,
    "v-no-record-card": NoRecordFoundCard,
    "v-modal-contract-edit": modalContractEdit
  },
  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Employees List", route: "/employees/list" },
      { title: "Employee Contract" }
    ]);
  },

  filters: {}
};
</script>
