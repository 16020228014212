<style lang="scss">
.modal-dialog {
  position: relative;
  width: auto;
  margin: 4.5rem auto;
}
#modal-contract-new___BV_modal_content_ {
  border-radius: 16px;
  position: relative;
  .top-icon {
    box-shadow: 0 1px 8px rgb(0 0 0 / 8%), 0 4px 24px rgb(28 36 43 / 16%);
    width: 64px;
    height: 64px;
    padding: 10px;
    border-radius: 16px;
    position: absolute;
    left: 24px;
    margin-top: -52px;

    background: white;
  }
  i {
    font-size: 12px;
  }
}
</style>
<template>
  <b-modal
    hide-header
    hide-footer
    style="margin-top: 100px"
    ref="modal-contract-new"
    id="modal-contract-new"
    ok-title="Save"
    title="Add New Employee's Contract"
  >
    <div class="top-icon">
      <img
        style="margin-left: 5px; margin-top: 6px; width: 40px"
        src="media/svg/add_contract_ico.svg"
        alt=""
      />
    </div>
    <div style="margin-top: 40px">
      <form @submit.stop.prevent="onSubmit">
        <b-form-group label="">
          <label for="">Start Date</label>
          <input
            :class="errors ? (errors['start_date'] ? 'is-invalid' : '') : ''"
            type="date"
            placeholder="optional"
            class="form-control"
            v-model="formdata.start_date"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            {{
              errors
                ? errors["start_date"]
                  ? errors["start_date"][0]
                  : ""
                : ""
            }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="">
          <label for="">End Date</label>
          <input
            :class="errors ? (errors['end_date'] ? 'is-invalid' : '') : ''"
            type="date"
            placeholder="optional"
            class="form-control"
            v-model="formdata.end_date"
          />
          <b-form-invalid-feedback id="input-1-live-feedback">
            {{
              errors ? (errors["end_date"] ? errors["end_date"][0] : "") : ""
            }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="">
          <label for="">Department</label>

          <select
            v-model="formdata.department"
            class="mb-3 mr-sm-3 mb-sm-0 custom-select"
            :class="errors ? (errors['department'] ? 'is-invalid' : '') : ''"
            @change="getDesignationByDepartmentId()"
          >
            <option value="">--Select Department--</option>
            <option
              v-for="(departmentItem, index) in getDepartments"
              :key="index"
              :value="departmentItem.id"
            >
              {{ departmentItem.name }}
            </option>
          </select>
          <b-form-invalid-feedback id="input-1-live-feedback">
            {{
              errors
                ? errors["department"]
                  ? errors["department"][0]
                  : ""
                : ""
            }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="" v-if="formdata.department != ''">
          <label for="">Designation</label>

          <select
            v-model="formdata.designation_id"
            class="mb-3 mr-sm-3 mb-sm-0 custom-select"
            :class="
              errors ? (errors['designation_id'] ? 'is-invalid' : '') : ''
            "
          >
            <option value="">--Select Designation--</option>
            <option
              v-for="(designationItem, index) in getDesignation"
              :key="index"
              :value="designationItem.id"
            >
              {{ designationItem.name }}
            </option>
          </select>
          <b-form-invalid-feedback id="input-1-live-feedback">
            {{
              errors
                ? errors["designation_id"]
                  ? errors["designation_id"][0]
                  : ""
                : ""
            }}
          </b-form-invalid-feedback>
        </b-form-group>
        <hr />

        <button
          class="btn btn-success btn-lg float-right"
          ref="kt_add_new_employee_contract"
        >
          <i class="flaticon2-add-1"></i>Save
        </button>
        <a
          class="btn btn-danger btn-lg float-right"
          @click="hideModal()"
          style="margin-right: 10px"
          ><i class="flaticon2-cancel-music"></i>Close</a
        >
      </form>
    </div>
  </b-modal>
</template>

<script>
import {
  FETCH_DEPARTMENTS,
  FETCH_DESIGNATIONS_BY_DEPARTMENT_ID,
  ADD_NEW_EMPLOYEE_CONTRACT
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      errors: [],
      formdata: {
        userId: this.$route.params.id,
        start_date: "",
        end_date: "",
        designation: "",
        department: ""
      }
    };
  },
  computed: {
    ...mapGetters(["getDepartments", "getDesignation"])
  },
  mounted() {
    this.$store.dispatch(FETCH_DEPARTMENTS);
  },
  methods: {
    getDesignationByDepartmentId() {
      // this.formdata.department!=""? this.formdata.department:this.formdata.designation=0;
      const departmentId =
        this.formdata.department != "" ? this.formdata.department : 0;
      this.$store.dispatch(
        this.$store.dispatch(FETCH_DESIGNATIONS_BY_DEPARTMENT_ID, departmentId)
      );
    },
    clearForm() {
      this.formdata = {
        start_date: "",
        end_date: "",
        designation_id: "",
        department: ""
      };
    },
    toast(variant = null, title, append = false, message) {
      this.$bvToast.toast(message, {
        title: title,
        toaster: "b-toaster-bottom-center",
        variant: variant,
        solid: true,
        appendToast: append
      });
    },

    hideModal() {
      this.$refs["modal-contract-new"].hide();
      this.clearForm();
    },
    onSubmit() {
      const formdata = this.formdata;

      // s spinner to submit button
      const submitButton = this.$refs["kt_add_new_employee_contract"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(ADD_NEW_EMPLOYEE_CONTRACT, formdata)
        // go to which page after successfully login
        .then(response => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.toast("success", "Success!", true, response.message);
          this.errors = [];
          this.hideModal();
        })
        .catch(errors => {
         
          this.errors = errors.errors;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;

          this.toast("danger", "Error!", true, errors.message);
        });
    }
  }
};
</script>
