<template>
  <b-modal id="modal-contract-edit" ok-title="Update" title="Edit Contact">
    <form>
      <b-form-group label="">
        <label for="">Start Date</label>
        <input
          :class="errors ? (errors['start_date'] ? 'is-invalid' : '') : ''"
          type="date"
          placeholder="optional"
          class="form-control"
          v-model="formdata.start_date"
        />
        <b-form-invalid-feedback id="input-1-live-feedback">
          {{
            errors ? (errors["start_date"] ? errors["start_date"][0] : "") : ""
          }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="">
        <label for="">End Date</label>
        <input
          :class="errors ? (errors['end_date'] ? 'is-invalid' : '') : ''"
          type="date"
          placeholder="optional"
          class="form-control"
          v-model="formdata.end_date"
        />
        <b-form-invalid-feedback id="input-1-live-feedback">
          {{ errors ? (errors["end_date"] ? errors["end_date"][0] : "") : "" }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="">
        <label for="">Department</label>

        <select
          v-model="formdata.department"
          class="mb-3 mr-sm-3 mb-sm-0 custom-select"
          :class="errors ? (errors['department'] ? 'is-invalid' : '') : ''"
          @change="getDesignationByDepartmentId()"
        >
          <option value="">--Select Department--</option>
          <option
            v-for="(departmentItem, index) in getDepartments"
            :key="index"
            :value="departmentItem.id"
          >
            {{ departmentItem.name }}
          </option>
        </select>
        <b-form-invalid-feedback id="input-1-live-feedback">
          {{
            errors ? (errors["department"] ? errors["department"][0] : "") : ""
          }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="">
        <label for="">Designation</label>

        <select
          v-model="formdata.designation"
          class="mb-3 mr-sm-3 mb-sm-0 custom-select"
          :class="errors ? (errors['designation'] ? 'is-invalid' : '') : ''"
        >
          <option value="">--Select Designation--</option>
          <option
            v-for="(designationItem, index) in getDesignation"
            :key="index"
            :value="designationItem.id"
          >
            {{ designationItem.name }}
          </option>
        </select>
        <b-form-invalid-feedback id="input-1-live-feedback">
          {{
            errors
              ? errors["designation"]
                ? errors["designation"][0]
                : ""
              : ""
          }}
        </b-form-invalid-feedback>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import {
  FETCH_DEPARTMENTS,
  FETCH_DESIGNATIONS_BY_DEPARTMENT_ID
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      errors: [],
      formdata: {
        start_date: "",
        end_date: "",
        designation: "",
        department: ""
      }
    };
  },
  computed: {
    ...mapGetters(["getDepartments", "getDesignation"])
  },
  mounted() {
    this.$store.dispatch(FETCH_DEPARTMENTS);
  },
  methods: {
    getDesignationByDepartmentId() {
      // this.formdata.department!=""? this.formdata.department:this.formdata.designation=0;
      const departmentId =
        this.formdata.department != "" ? this.formdata.department : 0;
      this.$store.dispatch(
        this.$store.dispatch(FETCH_DESIGNATIONS_BY_DEPARTMENT_ID, departmentId)
      );
    }
  }
};
</script>
